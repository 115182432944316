/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/purple-green.css";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

body {
    margin: 0;
    background-color: var(--color-background);
    overflow: visible;
}
.block-group {
    justify-content: space-between;
    box-sizing: border-box;
    display: flex;
    margin-block-start: 0;
    margin-block-end: 0;
    flex-wrap: wrap;
   
}

.block-spacer {
    clear: both;
}

.site-logo {
    display: block;
}

.block-site-title {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    font-style: normal;
    font-weight: 100;
    letter-spacing: 1px;
}

.block-site-title a {
    text-decoration: none;
    color: white;
    font-style: normal;
    font-weight: 100;
    letter-spacing: 1px;
}

.block-cta {
    display: block;
    width: 100%;
    text-align: center;
}

.block-cta-details {
    margin: calc(0.667em + 2px) calc(1.333em + 2px);
}

.block-cta-title {
    font-size:clamp(32.969px, 2.061rem + ((1vw - 3.2px) * 2.41), 59px);
    line-height:1.15;
}

.block-cta-container {
    display: flex;
    justify-content: center;
    padding-bottom: 0px;
}

.block-button {
    border-radius: 50px;
    color: #ffffff;
    background-color: #000000;
    flex: 1;
    text-align: center;
    display: inline-block;
    width: 7em;
    height: auto;
    padding: calc(0.667em + 2px) calc(1.333em + 2px);
    text-decoration: none;
}

.page{
    padding-bottom: 46px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--color-background);
}

.page-title{
    color: var(--color-primary);
    font-size: clamp(18.959px, 1.185rem + ((1vw - 3.2px) * 1.022), 30px);
}

.page-content{
    color: blue;
}

.page .section {
    background-color: var(--color-accent);
    padding: 0.5em 0.5em;
}

.page .section .content {
   font-size: clamp(18.959px, 1.185rem + ((1vw - 3.2px) * 1.022), 30px);
   overflow-wrap: break-word;
   word-break: break-word;
}

.block-columns {
    display: flex;
    flex: 1;
    box-sizing: border-box;
}

.block-column {
    flex-basis: 0;
    flex-grow: 1;
    
}

