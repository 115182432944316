@use '@angular/material' as mat;



// Be sure that you only ever include 'mat-core' mixin once!
// it should not be included for each theme.
@include mat.core(); 

$bv-brand: (
    50 : #e8ebfa,
    100 : #c6cdf2,
    200 : #f1c232,
    300 : #7a89e0,
    400 : #5e70d9,
    500 : #000000,
    600 : #3b4fcd,
    700 : #3246c7,
    800 : #2a3cc1,
    900 : #1c2cb6,
    A100 : #eceeff,
    A200 : #b9bfff,
    A400 : #8690ff,
    A700 : #6d79ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bv-light: (
    50 : #fffdf9,
    100 : #fffbf0,
    200 : #fff9e6,
    300 : #fff6db,
    400 : #fff4d4,
    500 : #fff2cc,
    600 : #fff0c7,
    700 : #ffeec0,
    800 : #ffecb9,
    900 : #ffe8ad,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
  

$bv-palette-primary: mat.define-palette($bv-brand);
$bv-palette-accent:  mat.define-palette($bv-light);

// include the custom theme components into a theme object
$bv-theme: mat.define-light-theme($bv-palette-primary, $bv-palette-accent, $bv-palette-primary);

// include the custom theme object into the angular material theme
@include mat.all-component-themes($bv-theme);

:root {
    --color-primary: #{mat.get-theme-color($bv-theme, primary, default)};
    --color-accent: #{mat.get-theme-color($bv-theme, accent, 700)};
    --color-background: #{mat.get-theme-color($bv-theme, accent, 100)};
    --color-background-alt: #{mat.get-theme-color($bv-theme, primary, A100)};
    --color-background-alt2: #{mat.get-theme-color($bv-theme, primary, A200)};
    --color-background-alt3: #{mat.get-theme-color($bv-theme, primary, A400)};
   
}